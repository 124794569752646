<template>
  <v-card class="mb-10" flat>
    <v-card-text v-for="(item, ind) in items" :key="ind">
      <v-row dense>
        <v-col cols="12" sm="8">
          <h3 class="mb-2">{{ item.NOME }}</h3>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="3">
          <strong> TOTAL: {{ convertCurrent(item.VALOR_TOTAL) }} </strong>
        </v-col>
        <v-col cols="12" sm="3">
          <strong>
            {{ !item.MOTORISTAS ? "C.EMISSÃO:" : "COMISSÃO" }}
            {{ convertCurrent(item.COMISSAO) }}
          </strong>
        </v-col>
        <v-col cols="12" sm="3" v-if="!item.MOTORISTAS">
          <strong>
            C.ENTREGA: {{ convertCurrent(item.COMISSAO_ENTREGA_VALOR) }}
          </strong>
        </v-col>
        <v-col cols="12" sm="3" v-if="!item.MOTORISTAS">
          <strong>
            C.TOTAL:
            {{
              item.COMISSAO_ENTREGA_VALOR
                ? convertCurrent(item.COMISSAO + item.COMISSAO_ENTREGA_VALOR)
                : convertCurrent(item.COMISSAO)
            }}
          </strong>
        </v-col>
      </v-row>
      <v-divider />
      <v-data-table :headers="headers" :items="item.DATA" class="elevation-0">
        <template v-slot:item.TOTAL="{ item }">
          {{ convertCurrent(item.TOTAL) }}
        </template>
        <template v-slot:item.DATA_HORA="{ item }">
          {{ convertDateTimeNew(item.DATA_HORA) }}
        </template>
        <template v-slot:item.COBRANCA="{ item }">
          {{ selectCobranca(item.COBRANCA) }}
        </template>

        <template v-slot:item.EXPEDIDOR="{ item }">
          {{ setExpedidorDesc(item.EXPEDIDOR) }}
        </template>
        <template v-slot:item.COD_BARRAS="{ item }">
          <MovimentacaoEncomendas :codBarras="item.COD_BARRAS" />
        </template>
      </v-data-table>
      <v-divider />
    </v-card-text>
  </v-card>
</template>

<script>
import mixin from "@/mixins";
import MovimentacaoEncomendas from "../../../../../components/MovimentacaoEncomendas.vue";
export default {
  props: ["items", "headers"],
  mixins: [mixin],
  components: { MovimentacaoEncomendas },
};
</script>
