<template >
  <div class="container" id="imprimir">
    <div
      class="d-flex align-items-md-start justify-content-between flex-column"
    >
      <div style="padding: 20px">
        <img
          src="@/assets/logoBlack.png"
          alt="[logo]"
          style="width: 60%; margin: 10px 20%"
        />
        <div class="text-center" style="margin-bottom: 10px">
          <u>FECHAMENTO DO CAIXA</u>
        </div>
        <div class="text-left" style="margin-bottom: 10px">
          Agência: {{ agencia.nome }}
        </div>
        <div class="text-left">Referente ao dia: {{ convertDate(data) }}</div>
        <div
          class="text-left"
          style="margin-bottom: 10px; border-bottom: 1px dashed #000"
        >
          Emissão: {{ dataHora }}
        </div>
        <div class="text-center" style="margin-bottom: 10px">
          <u>TOTAIS POR FORMA DE PAGAMENTO</u>
        </div>
        <div v-for="(fPgto, i) in valores" :key="i">
          <div style="border-bottom: 1px dashed #000; padding: 5px">
            {{ fPgto.descricao }}
            <span class="float-right">R${{ fPgto.valor }}</span>
          </div>
        </div>
        <div class="text-center" style="margin-bottom: 10px; margin-top: 10px">
          <u>MOVIMENTAÇÕES</u>
        </div>
        <div class="text-center" style="margin-bottom: 10px">
          ENTRADAS POR VENDAS: R$ {{ totais.vendas }}
        </div>
        <p class="text-center">
          {{ totais.passagens_vendidas }} Passagens Vendidas
        </p>
        <p class="text-center">
          {{ totais.encomendas_realizadas }} Encomendas Realizadas
        </p>
        <div class="text-left" style="border-bottom: 1px dashed #000"></div>
        <div class="text-center" style="margin-bottom: 10px; margin-top: 10px">
          <u>RESUMO</u>
        </div>
        <div style="border-bottom: 1px dashed #000; padding: 5px">
          ABERTURA DO CAIXA
          <span class="float-right">R$ {{ totais.abertura }}</span>
        </div>
        <div style="border-bottom: 1px dashed #000; padding: 5px">
          ENTRADAS (Manuais)
          <span class="float-right">R$ {{ totais.entradas }}</span>
        </div>
        <div style="border-bottom: 1px dashed #000; padding: 5px">
          SAÍDAS (Manuais)
          <span class="float-right">R$ {{ totais.saidas }}</span>
        </div>
        <div style="border-bottom: 1px dashed #000; padding: 5px">
          VENDAS (Passagens)
          <span class="float-right">R$ {{ totais.vendas }}</span>
        </div>
        <div style="border-bottom: 1px dashed #000; padding: 5px">
          ENCOMENDAS
          <span class="float-right">R$ {{ totais.total_encomendas }}</span>
        </div>
        <div style="border-bottom: 1px dashed #000; padding: 5px">
          TOTAL
          <span class="float-right">R$ {{ totais.fechamento }}</span>
        </div>
        <div class="text-center" style="margin-bottom: 10px; margin-top: 10px">
          <div style="border-top: 1px solid #000; margin-top: 50px"></div>
          <p>OPERADOR: {{ operador.login }}</p>
          <div style="border-top: 1px solid #000; margin-top: 30px"></div>
          <p>CPF</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dados", "data", "saidas", "entradas", "totais", "valores"],
  data() {
    return {
      agencia: "",
      dataHora: "",
      operador: "",
    };
  },
  created() {
    this.$http.get(`/agencias/${this.$store.getters.getOpAgency}`).then((r) => {
      this.agencia = r.data[0];
    });
    this.$http
      .get(`/operadores/${this.$store.getters.getOperador}`)
      .then((r) => {
        this.operador = r.data[0];
      });
    this.RetornaDataHoraAtual();
  },
  methods: {
    convertDate(date) {
      return date.split("-").reverse().join("/");
    },
    RetornaDataHoraAtual() {
      var dNow = new Date();
      var localdate =
        dNow.getDate() +
        "/" +
        (dNow.getMonth() + 1) +
        "/" +
        dNow.getFullYear() +
        " às " +
        dNow.getHours() +
        ":" +
        dNow.getMinutes();
      this.dataHora = localdate;
    },
  },
};
</script>
