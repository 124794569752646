var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-10",attrs:{"flat":""}},_vm._l((_vm.items),function(item,ind){return _c('v-card-text',{key:ind},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('h3',{staticClass:"mb-2"},[_vm._v(_vm._s(item.NOME))])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('strong',[_vm._v(" TOTAL: "+_vm._s(_vm.convertCurrent(item.VALOR_TOTAL))+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('strong',[_vm._v(" "+_vm._s(!item.MOTORISTAS ? "C.EMISSÃO:" : "COMISSÃO")+" "+_vm._s(_vm.convertCurrent(item.COMISSAO))+" ")])]),(!item.MOTORISTAS)?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('strong',[_vm._v(" C.ENTREGA: "+_vm._s(_vm.convertCurrent(item.COMISSAO_ENTREGA_VALOR))+" ")])]):_vm._e(),(!item.MOTORISTAS)?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('strong',[_vm._v(" C.TOTAL: "+_vm._s(item.COMISSAO_ENTREGA_VALOR ? _vm.convertCurrent(item.COMISSAO + item.COMISSAO_ENTREGA_VALOR) : _vm.convertCurrent(item.COMISSAO))+" ")])]):_vm._e()],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":item.DATA},scopedSlots:_vm._u([{key:"item.TOTAL",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertCurrent(item.TOTAL))+" ")]}},{key:"item.DATA_HORA",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDateTimeNew(item.DATA_HORA))+" ")]}},{key:"item.COBRANCA",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.selectCobranca(item.COBRANCA))+" ")]}},{key:"item.EXPEDIDOR",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setExpedidorDesc(item.EXPEDIDOR))+" ")]}},{key:"item.COD_BARRAS",fn:function(ref){
var item = ref.item;
return [_c('MovimentacaoEncomendas',{attrs:{"codBarras":item.COD_BARRAS}})]}}],null,true)}),_c('v-divider')],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }